import React from "react"
import Bandeau from "../components/bandeau"
import Container from 'react-bootstrap/Container'

const Prix = () => (

    <Bandeau title="Tarifs" dark>
        <Container >

            <table>
                <thead>
                    <tr className="darkText">
                        <td ><b>Massage</b></td><td><b>Durée</b></td><td><b>Tarif</b></td>
                    </tr>
                </thead>

                <tbody>

                    <tr className="blank_row"></tr>
                    <tr>
                        <td>Réflexologie Faciale - Découverte</td><td>30 Minutes</td><td>25 €</td>
                    </tr>
                    <tr>
                        <td>Réflexologie Faciale</td><td>45 Minutes</td><td>45 €</td>
                    </tr>
                    <tr>
                        <td>Réflexologie Faciale</td><td>60 Minutes</td><td>55 €</td>
                    </tr>


                    <tr className="blank_row"></tr>
                    <tr className="darkText">
                        <td>French Massage</td><td>60 Minutes</td><td>55 €</td>
                    </tr>
                    <tr className="darkText">
                        <td>French Massage</td><td>90 Minutes</td><td>85 €</td>
                    </tr>
                    <tr className="darkText">
                        <td>Sensoriel aux huiles</td><td>45 Minutes</td><td>50 €</td>
                    </tr>
                    <tr className="darkText">
                        <td>Sensoriel aux huiles</td><td>60 Minutes</td><td>60 €</td>
                    </tr>



                    <tr className="blank_row"></tr>
                    <tr>
                        <td>Massage des mains</td><td>30 Minutes</td><td>25 €</td>
                    </tr>
                    <tr>
                        <td>Massage plantaire</td><td>30 Minutes</td><td>25 €</td>
                    </tr>
                    <tr>
                        <td>Massage du dos aux huiles</td><td>45 Minutes</td><td>50 €</td>
                    </tr>



                    <tr className="blank_row"></tr>
                    <tr className="darkText">
                        <td>Au fil des saisons</td><td>90 Minutes</td><td>90 €</td>
                    </tr>


                    <tr className="blank_row"></tr>
                    <tr>
                        <td>Horoscope chinois - lecture des éléments</td><td>-</td><td>70 €</td>
                    </tr>
                    <tr>
                        <td>Horoscope chinois - lecture complète de la destinée</td><td>-</td><td>150 €</td>
                    </tr>

                    <tr className="blank_row"></tr>
                    <tr className="darkText">
                        <td>Numérobiologie et énergie du prénom</td><td>-</td><td>150 €</td>
                    </tr>




                    <tr className="blank_row"></tr>
                    <tr className="blank_row"></tr>

                    <tr>
                        <td colSpan="4">
                            Possibilité de se déplacer à domicile ou en entreprise.
                            Le tarif  sera établi en fonction du lieu de vie de la personne ou de l’entreprise.
                        </td>
                    </tr>
                </tbody>

            </table>
        </Container>
    </Bandeau>

)
export default Prix
