import React from "react"
import Layout from "../components/layout"

import SEO from "../components/seo"
import Prix from "../containers/prix"
import BonAchat from "../containers/bon-achat"


const TarifPage = () => (
  <Layout>
    <SEO title="Tarifs" />
    <Prix/>
    <BonAchat/>
  </Layout>

)
export default TarifPage
