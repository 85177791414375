import React from "react"
import Bandeau from "../components/bandeau"
import Image from 'react-bootstrap/Image'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
  }

const BonAchat = () => (

    <Bandeau title="Bon cadeau">
        <Container>
            <br></br>
            <Row>
                <Col sm lg={8}>
                <Image src={getPicturePath('bon_achat.png')} className="m-0"/>
                </Col>
                <Col sm lg={4}>
                    <p className="justified">
                        Plaisir d’offrir… N’hésitez pas à inviter vos proches,
                        vos amis à la détente en leur offrant un cadeau plein d’attention et de douceur.
                        Ils seront enchantés par cette nouvelle découverte et vous seront profondément reconnaissants
                        de leur avoir permis de vivre à leur tour un moment de profonde relaxation et de reconnexion à soi.
                    </p>
                </Col>  
            </Row>          
        </Container>
    </Bandeau>

)
export default BonAchat
